var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"min-height":_vm.modal.minHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"close-on-outside-click":_vm.modal.closeOnOutsideClick,"show-close-button":_vm.modal.showCloseButton,"visible":_vm.isOpen},on:{"hiding":_vm.closeModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('table',{staticClass:"table_form line-bin"},[_c('colgroup',[_c('col',{staticStyle:{"width":"130px"}}),_c('col',{staticStyle:{"width":"auto"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_msgContents('LLM.WORD.TEST_NAME', { defaultValue: '테스트 명칭' }))+" "),_c('span',{staticClass:"icon_require"},[_vm._v("필수항목")])])]),_c('td',[_c('DxTextBox',{staticClass:"mar_ri10",attrs:{"max-length":_vm.limitNumberTexts.maxLengths.name,"styling-mode":_vm.config.stylingMode,"width":200},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}},[_c('DxValidator',{attrs:{"validation-group":"validationGroupName"}},[_c('DxAsyncRule',{attrs:{"validation-callback":_vm.checkValidName,"message":_vm.$_msgContents('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                             { value: _vm.$_msgContents('LLM.WORD.TEST_NAME', { defaultValue: '테스트 명칭' }), defaultValue: '[테스트 명칭] 값은 중복될 수 없습니다.' })}}),_c('DxRequiredRule',{attrs:{"message":_vm.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                              { value: _vm.$_msgContents('LLM.WORD.TEST_NAME', {defaultValue: '테스트 명칭'}), defaultValue: '[테스트 명칭] 은/는 필수값 입니다' })}})],1)],1),_c('span',[_vm._v(" "+_vm._s(_vm.limitNumberTexts.textLengths.name ? _vm.limitNumberTexts.textLengths.name : _vm.formData.name ? _vm.formData.name.length : 0)+" ")]),_vm._v("/"+_vm._s(_vm.limitNumberTexts.maxLengths.name)+" ")],1)]),_c('tr',[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$_msgContents('LLM.MESSAGE.RUNNING_TEST_ALERT1', { defaultValue: '테스트 작업 진행에는 다소 시간이 걸릴 수 있습니다.' }))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$_msgContents('LLM.MESSAGE.RUNNING_TEST_ALERT2', { defaultValue: '테스트를 실행하시겠습니까?' }))+" ")])])])])])]},proxy:true}])},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'default filled txt_S medium' },
          text: this.$_msgContents('LLM.WORD.RUN', { defaultValue: '실행' }),
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          validationGroup: 'validationGroupName',
          onClick: _vm.runningTest,
        }}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'white filled txt_S medium' },
          text: this.$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          width: '120',
          height: '40',
          onClick: _vm.closeModal,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }